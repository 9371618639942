import React from "react";
import Header from "../../components/Landers/Header";
import Banner from "../../components/Landers/Banner";
import Brands from "../../components/Landers/BrandsTemp";
import AllCheck from "../../components/Landers/AllCheck";
import GetStarted from "../../components/Landers/GetStarted";
import SEO from "../../components/Landers/SEO";
import Testimonials from "../../components/Landers/Testimonials";
import Footer from "../../components/Landers/Footer";
import DataInfo from "../../components/Data";
import styles from "../../components/Landers/landers.module.scss";
import CustomizedSolutions from "../../components/Landers/CustomizedSolutions";
import Explore from "../../components/Landers/Explore";
import SectionTemplate from "../../components/Landers/SectionTemplate";

const HRPrefessional = [
  {
    h5: "Reliable Hiring with Built-in \nCompliance tools",
    p:
      "With SpringVerify’s advanced Blockchain technology, compliance teams can run reliable background checks for faster, accurate, and reliable hiring practices. And this ultimately leads to mitigating the risk of bad hires, legal issues and reducing bias while hiring.",
  },
  {
    h5: "Robust and Smart Toolset for \nReal-time Monitoring",
    p:
      "Compliance leaders have to stay up to date with the latest changes as per government laws and regulations. With SpringVerify’s built-in compliance toolset, employers can now bid farewell to all heavily regulated manual processes and always reflect the changes in regulations while hiring.",
  },
  {
    h5: "Unbiased, Compliant, and \nAccurate Candidate Screening",
    p:
      "SpringVerify’s advanced background screening solutions help employers follow a fair hiring process, thereby helping risk management teams avoid legal issues and maintain the brand’s reputation and values. ",
  },
];

const testimonials = [
  {
    name: "Rupali M ",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. 
    It provides real-time updates making our HR job easier. The entire process is so seamless saving us a lot of time, 
    effort and resources in the process. `,
  },
  {
    name: "Manvi K ",
    company: "Inshorts",
    p: `One thing that really stands-out is their GREAT customer service. 
    The Dedicated Account Manager responds in a very timely manner to any question at the drop of a hat and you really can't ask for more than that!`,
  },
  {
    name: "Nandita N",
    company: "Umlaut Pvt. Ltd.",
    p: `SpringVerify is a true all-rounder when it comes to background verifications.
    Most services I've used in the past have always had compromises. 
    But SpringVerify has been exceptional and impressive - right from the variety of checks it offers, 
    its reliablity to 24x7 customer support. `,
  },
];

export default () => (
  <div className={styles.ComplianceWrap}>
    <SEO
      title="Background Screening Service for Compliance Leaders | SpringVerify India"
      desc="SpringVerify's smart background screening solutions help compliance leaders and experts mitigate the risk of bad hires, thereby saving time and resources."
      url="https://in.springverify.com/compliance/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/roles/SV-IN+(Compliance).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={`Background Screening \nSolutions for \nCompliance Leaders`}
        p="Compliance and risk teams constantly need to overlook changes in rules and regulations
        regarding fair hiring practices. With SpringVerify, make better hiring decisions with thorough and accurate background checks."
        img="https://assets-sv-in.gumlet.io/LP/compliance/compliance-banner.svg"
      />
    </div>
    <Brands data={DataInfo.HRBrandsTemp} />
    <section>
      <SectionTemplate
        img="compliance/challenges.svg"
        h2={`Challenges faced by \nCompliance and Risk Teams`}
        p={`A compliant and unbiased hiring process is the primary goal for any employer. Risk \nleaders often face challenges in the below-mentioned cases,`}
        ul={[
          "To keep up with the changing compliance rules and regulations",
          "To avoid compliance violations, costly mis-hires and safeguard brand reputation",
          "Regular application of compliance changes while hiring at scale",
        ]}
      />
    </section>
    <AllCheck data={DataInfo.ComplianceAllCheck} />
    <section>
      <SectionTemplate
        img="compliance/ideal-choice.svg"
        h2={`SpringVerify - Ideal Choice \nfor Compliant and Fair Hiring`}
        p={`At SpringVerify, there’s no place for biased hiring and manual discrepancies. With \nthorough verifications, employers can ensure that fair hiring practices are in place. Here’s \nwhy Industry leaders trust SpringVerify,`}
        ul={[
          "Ability to run checks on multiple candidates at the same time",
          "Over 20+ checks offered under the same platform",
          "Workflow Automation increasing the productivity of Compliance teams",
          "Detailed documentation of screening reports for proper hiring practices",
          "Seamless integration with existing HRMS/ATS",
        ]}
      />
    </section>
    <CustomizedSolutions
      h2={"Customized Solutions for \nCompliance Leaders"}
      solutions={HRPrefessional}
      path="compliance"
    />
    <Explore data={DataInfo.Explore} />
    <Testimonials testimonials={testimonials} path="compliance" />
    <GetStarted
      img="compliance/compliance-getstarted.svg"
      h3="Screen your \ncandidate’s history \nwith SpringVerify now!"
    />
    <Footer />
  </div>
);
